<template>
  <div class="d-flex flex-column flex-root">
    <div
      class="error error-1 d-flex flex-row-fluid flex-column bgi-size-cover bgi-position-center bgi-no-repeat p-10 p-sm-30"
      :style="{
        backgroundImage: `url(${backgroundImage})`
      }"
    >
      <!-- begin:: Content -->
      <h1 class="error-title font-weight-boldest text-dark-75 mt-15">
        Oops !
      </h1>
      <p class="font-size-h3 font-weight-light mt-8">
        Une erreur est survenue !
      </p>
      <div class="mt-8">
        <router-link :to="{ name: 'dashboard' }" v-slot="{ href, navigate }">
          <a :href="href" @click="navigate" class="btn btn-primary">
            Retour vers Central Core
          </a>
        </router-link>
      </div>
      <!-- end:: Content -->
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/sass/pages/error/error-1.scss";
</style>
<script>
export default {
  name: "Error-1",
  mounted() {},
  computed: {
    backgroundImage() {
      return process.env.BASE_URL + "media/error/bg1.jpg";
    }
  }
};
</script>
